var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"app"}},[(_vm.isFrontPage)?_c('div',{staticClass:"start_screen"},[_c('p',{staticClass:"quiz_header"},[_vm._v("DOBRODOŠLI")]),_c('button',{staticClass:"button_active",on:{"click":_vm.startQuiz}},[_vm._v("POČETAK")])]):(!_vm.isFrontPage && !_vm.isQuizEnded)?_c('div',{staticClass:"quiz_container"},[_c('p',[_vm._v(_vm._s(_vm.counterCorrect)+"/"+_vm._s(_vm.nQuestions))]),(_vm.question.quesType == 0)?_c('div',[_c('p',{staticClass:"question_title"},[_vm._v(_vm._s(_vm.question.quesText))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputQuestion),expression:"inputQuestion"}],staticClass:"button_box",class:{
          
          correct: _vm.isAnswered && _vm.inputQuestion == _vm.question.answCor,
          wrong: _vm.isAnswered && _vm.inputQuestion != _vm.question.answCor
            
        },attrs:{"type":"text"},domProps:{"value":(_vm.inputQuestion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inputQuestion=$event.target.value}}})]):(_vm.question.quesType == 1)?_c('div',[_c('p',{staticClass:"question_title"},[_vm._v(_vm._s(_vm.question.quesText))]),_vm._l((_vm.question.answAll),function(answer,index){return _c('button',{key:index,staticClass:"button_box",class:{
          selected: _vm.singleAnswer == answer,
          correct: _vm.isAnswered && _vm.question.answCor == answer,
          wrong:
            _vm.isAnswered &&
            _vm.singleAnswer == answer &&
            _vm.question.answCor !== answer
        },on:{"click":function($event){return _vm.singlePick(answer)}}},[_vm._v(" "+_vm._s(answer)+" ")])})],2):_c('div',[_c('p',{staticClass:"question_title"},[_vm._v(_vm._s(_vm.question.quesText))]),_vm._l((_vm.question.answAll),function(answer,index){return _c('button',{key:index,staticClass:"button_box",class:{
          selected: _vm.multipleAnswer.includes(answer),
          correct: _vm.isAnswered && _vm.question.answCor.includes(answer),
          wrong:
            _vm.isAnswered &&
            _vm.multipleAnswer.includes(answer) &&
            !_vm.question.answCor.includes(answer),
        },on:{"click":function($event){return _vm.multiplePick(answer)}}},[_vm._v(" "+_vm._s(answer)+" ")])})],2),(!_vm.isAnswered)?_c('button',{staticClass:"button_active",on:{"click":_vm.checkAnswers}},[_vm._v("Potvrdi")]):_c('button',{staticClass:"button_active",on:{"click":_vm.nextQuestion}},[_vm._v("Sledeće pitanje")])]):_c('div',{staticClass:"start_screen"},[_c('p',{staticClass:"quiz_header"},[_vm._v("Tačno ste odgovorili na "+_vm._s(_vm.counterCorrect)+" od "+_vm._s(_vm.counterTotal)+" pitanja")]),_c('button',{staticClass:"button_active",on:{"click":_vm.ResetAll}},[_vm._v("POKUŠAJ PONOVO")])])])}
var staticRenderFns = []

export { render, staticRenderFns }