<template>
  <div id="app" class="container">
    <div v-if="isFrontPage" class="start_screen">
      <p class="quiz_header">DOBRODOŠLI</p>
      <button @click="startQuiz" class="button_active">POČETAK</button>
    </div>
    <div v-else-if="!isFrontPage && !isQuizEnded" class="quiz_container">
      <p>{{ counterCorrect }}/{{ nQuestions }}</p>
      <div v-if="question.quesType == 0">
        <p class="question_title">{{ question.quesText }}</p>
        <!-- dodavanje odgovarajuce klase u zavisnosti da li je odgovor tacan ili ne -->
        <input type="text" v-model="inputQuestion"  class="button_box" :class="{
            
            correct: isAnswered && inputQuestion == question.answCor,
            wrong: isAnswered && inputQuestion != question.answCor
              
          }"/>
      </div>

      <div v-else-if="question.quesType == 1">
        <p class="question_title">{{ question.quesText }}</p>
        <!-- dodavanje odgovarajuce klase u zavisnosti da li je odgovor tacan ili ne -->
        <button
          class="button_box"
          v-for="(answer, index) in question.answAll"
          :key="index"
          @click="singlePick(answer)"
          :class="{
            selected: singleAnswer == answer,
            correct: isAnswered && question.answCor == answer,
            wrong:
              isAnswered &&
              singleAnswer == answer &&
              question.answCor !== answer
          }"
        >
          {{ answer }}
        </button>
      </div>
      <div v-else>
         <p class="question_title">{{ question.quesText }}</p>
         <!-- dodavanje odgovarajuce klase u zavisnosti da li su odgovori tacni ili ne -->
        <button
          class="button_box"
          v-for="(answer, index) in question.answAll"
          :key="index"
          @click="multiplePick(answer)"
          :class="{
            selected: multipleAnswer.includes(answer),
            correct: isAnswered && question.answCor.includes(answer),
            wrong:
              isAnswered &&
              multipleAnswer.includes(answer) &&
              !question.answCor.includes(answer),
          }"
        >
          {{ answer }}
        </button>
      </div>
      <button @click="checkAnswers" v-if="!isAnswered" class="button_active">Potvrdi</button>
      <button @click="nextQuestion" v-else class="button_active">Sledeće pitanje</button>
    </div>
    <div v-else class="start_screen">
      <p class="quiz_header">Tačno ste odgovorili na {{ counterCorrect }} od {{ counterTotal }} pitanja</p>
      <button @click="ResetAll" class="button_active">POKUŠAJ PONOVO</button>
    </div>
  </div>
</template>

<script>
import pitanja from "@/pitanja.json";
export default {
  name: "App",
  components: {},
  data() {
    return {
      isFrontPage: true,
      isQuizEnded: false,
      questions: pitanja,
      question: null,
      inputQuestion: "",
      counterCorrect: 0,
      counterTotal: 0,
      nQuestions: 5,
      singleAnswer: "",
      multipleAnswer: [],
      isAnswered: false,
    };
  },

  methods: {
    // Pokretanje vezbanke
    startQuiz() {
      this.getQuestion();
      this.isFrontPage = false;
    },
    // Dohvatanje jednog elementa niza questions
    getQuestion() {
      if (this.counterTotal == this.nQuestions) {
        this.isQuizEnded = true;
        return;
      }
      // random biranje clana niza
      var randomElement = Math.floor(Math.random() * this.questions.length);
      // smestanje clana niza u promenljivu question
      this.question = this.questions[randomElement];
      // brisanje izabranog clana niza iz niza questions
      this.questions.splice(randomElement, 1);
      this.counterTotal++;
    },
    //provera odgovora po tipu pitanja
    checkAnswers() {
      switch (this.question.quesType) {
        //input
        case "0":
          if (this.inputQuestion == this.question.answCor) {
            this.counterCorrect++;
          }
          break;
          //ukoliko je jedan tacan odgovor
        case "1":
          if (this.singleAnswer == this.question.answCor) {
            this.counterCorrect++;
          }
          break;
          //ukoliko je vise tacnih odgovora
        case "2":
          var arr1 = this.multipleAnswer.sort();
          var arr2 = this.question.answCor.sort();
          if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
            this.counterCorrect++;
          }
          break;
        default:
          break;
      }
      this.isAnswered = true;
    },
    //odabir jednog odgovora od ponudjenih
    singlePick(answer) {
      if (this.singleAnswer == answer) {
        this.singleAnswer = "";
      } else {
        this.singleAnswer = answer;
      }
    },
    //odabir vise ponudjenih odgovora
    multiplePick(answer) {
      if (this.multipleAnswer.includes(answer)) {
        var index = this.multipleAnswer.indexOf(answer);
        this.multipleAnswer.splice(index, 1);
      } else {
        this.multipleAnswer.push(answer);
      }
    },
    ResetAll() {
      this.isFrontPage = true;
      this.isQuizEnded = false;
      this.questions = pitanja;
      this.question = null;
      this.inputQuestion = "";
      this.counterCorrect = 0;
      this.counterTotal = 0;
      this.singleAnswer = "";
      this.multipleAnswer = [];
    },
  nextQuestion() {
    this.isAnswered = false;
    this.getQuestion();
    this.inputQuestion = "";
    this.singleAnswer = "";
    this.multipleAnswer = [];
  }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100vh;
  background: #c2dfec;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quiz_header{
    font-weight: bold;
    font-size:2.2rem;
    margin-bottom: 1rem !important;
    padding: 1.5rem;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}
.quiz_container{
  width:100%;
    margin:auto;
}
.start_screen{
  width:100%;
  margin:auto;
}
.container{

	max-width: 50rem;
  width: 50rem;
  min-height: 40rem;
	background: #FAFAFA;
  position: relative;
  display: flex;
	border-radius: 0.5rem;
	overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.question_title{
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
}
button{
  cursor: pointer;
  transition: all 0.3s;
}
button:hover{
  transform: scale(1.08);
}
.button_box{
    border-radius: 20px;
    padding: 9px 18px;
    margin: 0 18px;
    margin-bottom: 12px;
    transition: 0.3s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.85);
    border:  transparent 2px solid;
    width: 80%;
    text-align: center;
}
.button_active{
  padding: 0.5rem 1rem;
    border: 2px solid #86a6b4;
    background-color: #c2dfec;
    border-radius: 5rem;
    margin: 1rem 0.25rem;
    
}

input:focus {
    outline: none !important;
}
.selected {
  border-radius: 20px;
  border-color: rgba(7, 7, 7, 0.25);
  background-color: white;
}

.correct {
  border: 2px solid rgb(65, 224, 65);
}
.wrong {
  border: 2px solid red;
}


</style>
